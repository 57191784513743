/* eslint-disable */

// Import Doka itself
import { supported } from './JirutoDoka/lib/doka.esm.min';
import './JirutoDoka/lib/doka.min.css';

// Import Doka Vue Components
import Doka from './JirutoDoka/components/Doka.vue';
import DokaModal from './JirutoDoka/components/DokaModal.vue';
import DokaOverlay from './JirutoDoka/components/DokaOverlay.vue';

// Utils
import { toURL } from './JirutoDoka/utils/toURL';

// Test once if Doka is supported on this environment
const isSupported = supported();

export {
    // Components
    Doka,
    DokaModal,
    DokaOverlay,

    // Utilities
    toURL,
    isSupported
}
