<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="relative bg-white rounded-md shadow-xl-white sm:rounded-md my-10 p-1" :class="{'z-50': enabled}">

            <DokaOverlay
                    class="object-cover sm:h-48 md:h-72 lg:h-80 w-full rounded-md"
                    :enabled="enabled"
                    :src="imageSource"
                    :crop="imagePreviewCrop"
                    :dokaUtils="dokaUtils"
                    @confirm="handleDokaConfirm"
                    @cancel="handleDokaCancel">
                <img class="object-cover" :src="imagePreviewSource" alt="">

            </DokaOverlay>
            <div v-if="canEdit" class="absolute right-3 top-3">
                <input type="file" ref="changeHeroImage" @change="heroImageSelected" class="hidden">
                <button @click="editHeroImage" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200 opacity-75 hover:opacity-100 border-0">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                </button>
            </div>
            <div v-if="enabled && canEdit" class="absolute left-3 top-3">
                <input type="file" ref="changeHeroImage" @change="heroImageSelected" class="hidden">
                <button @click="uploadNewImage" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200 opacity-75 hover:opacity-100 border-0" style="z-index: 2000">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                </button>
            </div>
            <div v-if="showArtCredit && !enabled" class="absolute left-3 bottom-3">
                      <span class="block w-full rounded-md shadow-sm">
                        <button @click="goToArtist" type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-jiruto-main opacity-75 hover:opacity-100  focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Cover photo art by Chen Ohana
                        </button>
                      </span>
            </div>

            <div v-if="showProgressBar" class="absolute w-full top-3 pt-1 px-20">
                <div class="flex w-full mb-2 items-center justify-between">
                    <div>
                        <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-700 bg-purple-300">Uploading</span>
                    </div>
                    <div class="text-right">
                        <span class="text-xs font-semibold inline-block text-purple-700">
                            {{ uploadProgress }}%
                        </span>
                    </div>
                </div>
                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                    <div :style="progressBar" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                </div>
            </div>

        </div>




    </div>
</template>

<script>
    import { DokaOverlay, toURL } from '../../vue-doka';
    import * as axios from '../../axios-auth';
    import * as fb from '../../firebaseConfig';
    export default {
        name: "ProfileHero",
        props: {
            heroPreviewProp: {},
            heroOriginalProp: {},
            canEdit: {},
        },
        components: { DokaOverlay,
        },
        data () {
            return {
                enabled: false,
                imagePreviewCrop: {
                    aspectRatio: 10/35.7,
                    zoom: 1.5,
                    // rotation: -1.5707963268
                },
                showAlertNotification: false,
                dokaUtils: ['crop', 'filter', 'color'],
                imagePreviewSource: {},
                imageSource: {},
                defaultSource: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2ForiginalHeroImage.jpg?alt=media&token=ca229dcd-c043-470b-9457-ba1e608b64c6",
                originalProgress: 0,
                editedProgress: 0,
                uploadUrlProgress: 0,
                showProgressBar: false,
            }
        },
        created(){
            this.setupPreview()
            this.setupImageSource()
        },
        computed: {
            progressBar(){
                return {
                    'width': this.uploadProgress + "%",
                    'transition-duration': '1s'
                }
            },
            uploadProgress(){
                return Math.floor(this.originalProgress + this.editedProgress + this.uploadUrlProgress)
            },
            showArtCredit(){
                return this.heroOriginalProp === this.defaultSource
            },
        },
        watch: {
            heroPreviewProp(){
                const preview = this.heroPreviewProp === "" ? this.defaultSource : this.heroPreviewProp
                this.downloadImage(preview)
                    .then((file) => {
                        this.imagePreviewSource = file
                        console.log("Image Preview source is", this.imagePreviewSource)
                    })
                    .catch((err) => { console.error("ERORR: Downloaidng image failed", err) })
            },
            heroOriginalSource(){
                const original = this.heroOriginalProp === "" ? this.defaultSource : this.heroOriginalProp
                this.imageSource = this.downloadImage(original)
                    .then((file) => {
                        console.log("File is: ", file)
                    })
                    .catch((err) => { console.error("ERORR: Downloaidng image failed", err) })
            }
        },
        methods: {
            goToArtist(){
                window.open('https://www.instagram.com/chen.ostudio/', '_blank');
            },
            setupPreview(){
                const preview = this.heroPreviewProp === "" ? this.defaultSource : this.heroPreviewProp
                this.imagePreviewSource = preview
            },
            setupImageSource(){
                const original = this.heroOriginalProp === "" ? this.defaultSource : this.heroOriginalProp
                this.downloadImage(original)
                    .then((file) => {
                        this.imageSource = file
                    })
                    .catch((err) => { console.error("ERORR: Downloaidng image failed", err) })
            },
            resetProgressBars(){
                this.originalProgress = 0
                this.editedProgress = 0
                this.uploadUrlProgress = 0
            },
            updateHeroImage(payload){
                this.resetProgressBars()
                this.showProgressBar = true
                const storageRef = fb.storage.ref();

                let originalPromise = new Promise((resolve, reject) => {
                    let uploadTask = storageRef.child("userProfile").child(`${fb.auth.currentUser.uid}/originalHeroImage`).put(payload.original)
                    uploadTask.on('state_changed',  (snapshot) => {
                        this.originalProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 90 / 2;
                    }, (err) => {
                        console.error("ERROR: storing original image FAILED", err)
                        reject(err)
                    }, () =>{
                        uploadTask.snapshot.ref.getDownloadURL()
                            .then( (downloadURL) => { resolve(downloadURL) })
                    })
                });

                let editedPromise = new Promise((resolve, reject) => {
                    let uploadTask = storageRef.child("userProfile").child(`${fb.auth.currentUser.uid}/editedHeroImage`).put(payload.edited)
                    uploadTask.on('state_changed', (snapshot) => {
                        this.editedProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 90 / 2;
                    }, (err) => {
                        console.error("ERROR: storing edited image FAILED", err)
                        reject(err)
                    }, () => {
                        uploadTask.snapshot.ref.getDownloadURL()
                            .then((downloadURL) => { resolve(downloadURL) })
                    })
                })
                Promise.all([originalPromise, editedPromise])
                    .then((values) => { return  { original: values[0], edited: values[1] } })
                    .then((imageRequest) => {
                        this.uploadUrlProgress = 0
                        return axios.jigo.put(`/v2/user/profile/heroImage`, imageRequest)
                            .then((resp) => {
                                if(resp.status === 201){
                                    this.uploadUrlProgress = 10
                                    // this.updateHeroUrlLocally(imageRequest.edited)
                                    setTimeout(() => {
                                        this.showProgressBar = false
                                    }, 256)
                                } else { console.error("ERROR: uploading image urls FAILED") }
                            })
                            .catch((err) => { console.error("ERROR: uploading image urls FAILED", err) })
                    })
                    .catch((err) => { console.error("ERROR: Promises.all FAILED", err) })
            },
            downloadImage(url) {
                return axios.clean.get(url, {responseType : 'blob'})
                    .then((resp) => {
                        return resp.data
                    })
                    .catch((err) => { console.error("ERROR: fetch image blog FAILED", err) })
            },
            uploadNewImage(){
                this.$refs.changeHeroImage.click()
            },
            editHeroImage(){
                this.setupPreview()
                this.setupImageSource()
                this.enabled = true
            },
            handleDokaConfirm(output) {
                console.log('Confirm crop!', output.file, this.imageSource);
                this.enabled = false;
                this.imagePreviewSource = toURL(output.file);
                this.imagePreviewCrop = output.data.crop;

                this.updateHeroImage({original: this.imageSource, edited: output.file})
            },
            handleDokaCancel() {
                console.log('Cancel crop!');
                this.enabled = false;
            },
            heroImageSelected(e){
                const file = e.target.files[0]
                console.log("Hero Image selected", file)
                console.log("File size: ", file.size)
                if (file.size >= 2000*1024){
                    this.$emit("sizeAlert")
                } else {
                    this.imageSource = e.target.files[0]
                }

            },
            editImage(){
                console.log("Changing hero!")
                this.$emit("editImage", "hero")
            },
        }
    }
</script>

<style scoped>

</style>


<!-- this.downloadImage(preview)-->
<!--     .then((file) => {-->
<!--         console.log("File issss:", file)-->
<!--         this.imagePreviewSource = file-->
<!--     })-->
<!--     .catch((err) => { console.error("ERORR: Downloaidng image failed", err) })-->
