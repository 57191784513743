<template>

<!--    <div id="demo-modal">-->

<!--        <h2>Doka Modal</h2>-->

<!--        <button @click="enabled=true">Show Modal</button>-->

<!--        <DokaModal-->
<!--                crop-aspect-ratio="1"-->
<!--                :src="src"-->
<!--                v-if="enabled"-->
<!--                @confirm="handleDokaConfirm"-->
<!--                @cancel="handleDokaCancel"-->
<!--                @close="close"/>-->

<!--        <img v-if="result" :src="result" alt="" />-->

<!--    </div>-->


    <div  v-if="open" class="fixed z-2000 modal-mask overflow-y-auto bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">



        <transition enter-class="transition opacity-0"
                    enter-active-class="transform ease-out duration-300"
                    enter-to-class="transition opacity-100"
                    leave-class="transition opacity-100"
                    leave-active-class="transform ease-in duration-200"
                    leave-to-class="transition opacity-0"
        >
            <div v-if="open" class="fixed z-500 inset-0 transition-opacity">
                <div class="absolute overflow-y-scroll inset-0 bg-gray-500 opacity-75"></div>
            </div>
        </transition>


        <transition enter-class="transition opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-active-class="transform ease-out duration-300"
                    enter-to-class="transition opacity-100 translate-y-0 sm:scale-100"
                    leave-class="transition opacity-100 translate-y-0 sm:scale-100"
                    leave-active-class="transform ease-in duration-200"
                    leave-to-class="transition opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >

            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div v-if="open" v-on-clickaway="close" class=" bg-black rounded-lg  overflow-hidden shadow-xl transform transition-all sm:rounded-lg my-5">

                    <div class="">
                        <input type="file" @change="changeImage" ref="imageInput" class="hidden">

                        <div class="shadow sm:rounded-md sm:overflow-hidden">

                                <div class="m-2 rounded-md">
                                    <Doka
                                            ref="doka"
                                            style="height:600px; width:600px"
                                            crop-aspect-ratio="1"
                                            :allow-drop-files="true"
                                            :crop-aspect-ratio-options="cropOptions"
                                            :src="dokaSource"
                                            :utils="dokaUtils"
                                            @confirm="handleDokaConfirm"
                                            @cancel="handleDokaCancel"/>
                                    <div class="absolute right-1 top-1 z-50">
                                        <button @click="selectNewImage" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-400 p-2 hover:bg-gray-300 border-0">
                                            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                                        </button>
                                    </div>
                                </div>

                                <div class="hidden px-4 py-5 bg-white sm:p-6">

                                    <div class="max-w-sm rounded overflow-hidden shadow-lg">
                                        <img alt="altered" ref="source" :src="src" crossorigin>
                                    </div>

                                    <div class="flex justify-center mt-5 w-full h-full ">
                                        <div v-if="previewCropped" class="bg-gray-500 justify-center  max-w-xs rounded-full overflow-hidden shadow-lg">
                                            <img alt="altered" :src="result" crossorigin>
                                        </div>
                                    </div>
                                </div>

                            <div v-if="showProgressBar" class="relative pt-1 px-2">
                                <div class="flex mb-2 items-center justify-between">
                                    <div>
                                      <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-purple-700 bg-purple-300">
                                        Uploading
                                      </span>
                                    </div>
                                    <div class="text-right">
                                      <span class="text-xs font-semibold inline-block text-purple-700">
                                        {{ progress }}%
                                      </span>
                                    </div>
                                </div>
                                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                    <div :style="progressBar" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500"></div>
                                </div>
                            </div>


<!--                                <div v-if="previewCropped" class="flex justify-center px-4 py-3 bg-black text-right sm:px-6">-->
<!--                                    <span class="inline-flex rounded-md shadow-sm">-->
<!--                                        <input type="file" @change="changeImage" ref="imageInput" class="hidden">-->
<!--                                        <button @click="selectNewImage" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">-->
<!--                                            Upload New-->
<!--                                        </button>-->
<!--                                    </span>-->
<!--&lt;!&ndash;                                    <span class="inline-flex rounded-md shadow-sm">&ndash;&gt;-->
<!--&lt;!&ndash;                                      <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">&ndash;&gt;-->
<!--&lt;!&ndash;                                        Crop & Save&ndash;&gt;-->
<!--&lt;!&ndash;                                      </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--                                </div>-->
                            </div>
                    </div>

                </div>
            </div>
        </transition>
    </div>


</template>

<script>
    import  {Doka} from "../vue-doka";

    // import {DokaModal, toURL} from 'Doka'
    // import '../doka.min.css';
    import {directive as onClickaway} from 'vue-clickaway'
    import Cropper from "cropperjs";
    import debounce from 'lodash/debounce'
    export default {
        name: "ImageCropper",
        directives: {
            onClickaway
        },
        props: {
            src: {},
            showProgressBar: {},
            progress: {},
            open: Boolean,
        },

        components: { Doka },

        data() {
            return {
                cropper: null,
                destination: {},
                image: {},
                enabled: true,
                // cropper: null,
                // objectUrl: null,
                previewCropped: null,
                result: null,
                selectedFile: null,
                debouncedUpdatePreview: debounce(this.updatePreview, 257),
                dokaSource: null,
                dokaUtils: ['crop', 'filter', 'color'],
                cropOptions: [
                    // {
                    //     label: 'Free',
                    //     value: null
                    // },
                    // {
                    //     label: 'Portrait',
                    //     value: 1.5
                    // },
                    // {
                    //     label: 'Square',
                    //     value: 1
                    // },
                    // {
                    //     label: 'Landscape',
                    //     value: .75
                    // }
                ],
            }
        },
        created(){
            this.setupCropper(this.src)
            this.dokaSource = this.src
            this.enabled = this.open
        },
        computed: {
            progressBar(){
                return {
                    'width': this.progress + "%",
                    'transition-duration': '1s'
                }
            }
        },
        methods: {
            preview(e){
                console.log("Preview:", e)
            },
            changeImage(e){
                const image = e.target.files[0]
                this.dokaSource = e.target.files[0]
                this.setupCropper(image)
            },
            selectNewImage(){
                this.$refs.imageInput.click()
            },
            handleDokaConfirm(output) {
                this.$emit('updateImage', {original: this.dokaSource, edited: output.file})
            },
            handleDokaCancel() {
                console.log('Cancel crop!');
                this.$emit("close")
            },
            resetCropper () {
                this.cropper.reset()
            },
            rotateLeft () {
                this.cropper.rotate(-90)
            },
            rotateRight () {
                this.cropper.rotate(90)
            },
            setupCropper (selectedFile) {
                if (this.cropper) {
                    this.cropper.destroy()
                }

                // if (this.objectUrl) {
                //     window.URL.revokeObjectURL(this.objectUrl)
                // }

                if (!selectedFile) {
                    this.cropper = null
                    this.objectUrl = null
                    this.previewCropped = null
                    return
                }

                // this.objectUrl = window.URL.createObjectURL(selectedFile)
                this.$nextTick(this.setupCropperInstance)
            },
            setupCropperInstance () {
                this.cropper = new Cropper(this.$refs.source, {
                    aspectRatio: 1,
                    crop: this.updatePreview
                })
            },
            updatePreview: debounce(function () {
                const canvas = this.cropper.getCroppedCanvas()
                this.previewCropped = canvas.toDataURL('image/png')
            }, 30),
            close() {
                this.$emit('close')
                this.enabled = false
            },
        },
        watch: {
            src(){
                this.setupCropper(this.src)
                this.dokaSource = this.src
            }
        },
        // mounted() {
        //     this.image = this.$refs.image;
        //     this.cropper = new Cropper(this.image, {
        //         zoomable: false,
        //         scalable: false,
        //         aspectRatio: 1,
        //         crop: () => {
        //             const canvas = this.cropper.getCroppedCanvas();
        //             this.destination = canvas.toDataURL("image/png");
        //         }
        //     });
        // }
    }
</script>

<style scoped>

</style>
