<template>

    <div v-if="loadedUser" class="relative my-20 max-w-7xl mx-auto sm:px-6 lg:px-8">

        <profile-hero :canEdit="canEdit" @sizeAlert="sizeAlert" :heroOriginalProp="user.profile.heroImageOriginal"
                      :heroPreviewProp="user.profile.heroImageEdited" @editImage="editProfileImage"/>

        <profile-intro :canEdit="canEdit" @editImage="editProfileImage" @sizeAlert="sizeAlert" v-if="loadedUser" :userProp="user" @updateInterests="updateInterests" @updateHeadline="updateHeadline" @editPressed="editPressed" ref="profileIntro" />
        <profile-stats v-if="stats" :stats="stats"/>
        <profile-about  :canEdit="canEdit" v-if="loadedUser" :aboutProp="user.profile.about" @updateAbout="updateAbout" @editPressed="editPressed" ref="profileAbout" />

        <div v-if="communities" class="bg-white rounded-md shadow-xl-white overflow-hidden sm:rounded-md my-10">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <h1 class="text-3xl leading-6 font-bold text-gray-900">
                            {{ username }}'s communities
                        </h1>
                    </div>
                </div>
            </div>
            <paginated-list :maxInPage="5" :results="communities" listType="profile" showUsername="false"/>
        </div>

        <div v-if="collaborations" class="bg-white rounded-md shadow-xl-white overflow-hidden sm:rounded-md my-10">
            <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div class="bg-white px-4 py-5 sm:px-6">
                        <h1 class="text-3xl leading-6 font-bold text-gray-900">
                            {{ username }}'s Collaborations
                        </h1>
                    </div>
                </div>
            </div>
            <paginated-list  :results="collaborations" listType="profile" showUsername="false"/>
        </div>

        <!--TODO: Make a round preview since the final profile image is round -->
        <image-cropper ref="imageCropper" :showProgressBar="showProfileImageProgressBar" v-if="showImageCropper" :src="imageCropperSource" :open="showImageCropper"
                       @close="closeImageCropper" @updateImage="updateProfileImage" :progress="uploadProgress"
        />

        <alertNotification class="z-100" style="z-index: 50" :open="showAlertNotification" notificationType="alert">
            <template v-slot:header>
                The image is too large
            </template>
            <template v-slot:text>
                We have a size limit of 2MB.
            </template>
        </alertNotification>

    </div>

</template>

<script>
    import PaginatedList from "../../components/PaginatedList";
    import {mapGetters} from 'vuex'
    import ProfileHero from "./ProfileHero";
    import ProfileAbout from "./ProfileAbout";
    import ProfileStats from "./ProfileStats";
    import ProfileIntro from "./ProfileIntro";
    import ImageCropper from "../../components/ImageCropper";
    import _ from 'lodash'
    import ActionNotification from '../../components/ActionNotification'

    import * as axios from '../../axios-auth'
    import * as fb from '../../firebaseConfig'
    export default {
        name: "Profile",
        props: ['username'],
        metaInfo(){
            const title = "Jiruto profile: " + " @" + this.username

            if (this.user !== null){
                return{
                    title: "Jiruto profile: " + this.user.firstName + " " + this.user.lastName + " @" + this.username,
                    meta: [
                        {vmid: 'og:title', property: "og:title", name:'ogTitle', content: `Jiruto Profile: ${this.user.firstName} ${this.user.lastName} | @${this.user.username}`},
                        // {vmid: 'og:url', property: "og:url", name:'ogUrl', content: "https://Jiruto.com"},
                        {vmid: "og:image", property: "og:image", name:'ogImage', content: this.user.avatarUrl},
                        {vmid: 'og:description', property: "og:description", name:'ogDescription', content: this.user.profile.headline},

                        {vmid: "twitter:image", name: "twitter:image", content: this.user.avatarUrl},
                        {vmid: 'twitter:description', name: "description", content: this.user.profile.headline},
                        {vmid: 'twitter:title', name: "twitter:title", content: title},
                        // {vmid: 'twitter:url', property: "twitter:url", name:'twitterUrl', content: "https://Jiruto.com"},
                        {vmid: 'twitter:description', name: "twitter:description", content: this.user.profile.headline},
                    ]
                }
            }

        },
        created(){
            this.fetchUserProfile()
            this.fetchUserCommunities()
            this.fetchUserCollaborations()
            this.fetchUserStats()
        },
        watch: {
            username(){
                this.user = null
                this.fetchUserProfile()
                this.fetchUserCommunities()
                this.fetchUserCollaborations()
                this.fetchUserStats()
            }
        },
        data(){
            return {
                showAlertNotification: false,
                showImageCropper: false,
                imageCropperSource: null,
                user: null,
                // user: {
                //     firstName: null,
                //     lastName: null,
                //     profile: {
                //         headline: null,
                //     },
                //     username: null,
                // },
                collaborations: null,
                communities: null,
                stats: null,
                originalProgress: null,
                editedProgress: null,
                uploadUrlProgress: null,
                showProfileImageProgressBar: false,
            }
        },
        components: {
            AlertNotification: ActionNotification,
            ImageCropper,
            ProfileIntro,
            ProfileStats,
            ProfileAbout,
            ProfileHero,
            PaginatedList
            // usersGrid
        },
        computed: {
            ...mapGetters(['getAvatarUrl','getUser','getUsername']),
            loadedUser(){
                return this.user !== null
            },
            uploadProgress(){
                return Math.floor(this.originalProgress + this.editedProgress + this.uploadUrlProgress)
            },
            canEdit(){
                return this.username === this.getUsername
            },
        },
        methods: {

            sizeAlert(){
                this.showAlertNotification = true
                setTimeout(() => {
                    this.showAlertNotification = false
                }, 5000)
            },
            resetProgressBars(){
                this.originalProgress = 0;
                this.editedProgress = 0;
                this.uploadUrlProgress = 0;
            },
            updateProfileImage(payload){
                this.resetProgressBars();
                this.showProfileImageProgressBar = true;
                const storageRef = fb.storage.ref();

                let originalPromise = new Promise((resolve, reject) => {
                    let uploadTask = storageRef.child("userProfile").child(`${fb.auth.currentUser.uid}/originalProfileImage`).put(payload.original)
                    uploadTask.on('state_changed',  (snapshot) => {
                        this.originalProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 90 / 2;
                    }, (err) => {
                        console.error("ERROR: storing original image FAILED", err)
                        reject(err)
                    }, () =>{
                        uploadTask.snapshot.ref.getDownloadURL()
                            .then( (downloadURL) => { resolve(downloadURL) })
                    })
                });

                let editedPromise = new Promise((resolve, reject) => {
                    let uploadTask = storageRef.child("userProfile").child(`${fb.auth.currentUser.uid}/editedProfileImage`).put(payload.edited)
                    uploadTask.on('state_changed', (snapshot) => {
                        this.editedProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 90 / 2;
                    }, (err) => {
                        console.error("ERROR: storing edited image FAILED", err)
                        reject(err)
                    }, () => {
                        uploadTask.snapshot.ref.getDownloadURL()
                            .then((downloadURL) => { resolve(downloadURL) })
                    })
                })
                Promise.all([originalPromise, editedPromise])
                    .then((values) => { return  { original: values[0], edited: values[1] } })
                    .then((imageRequest) => {
                        this.uploadUrlProgress = 0
                        return axios.jigo.put(`/v2/user/profile/profileImage`, imageRequest)
                            .then((resp) => {
                                if(resp.status === 201){
                                    this.uploadUrlProgress = 10
                                    this.updateAvatarUrlLocally(imageRequest.edited)
                                    this.user.profile.profileImageOriginal = imageRequest.original
                                    setTimeout(() => {
                                        this.showProfileImageProgressBar = false
                                        this.$refs.imageCropper.close()
                                    }, 256)
                                } else { console.error("ERROR: uploading image urls FAILED") }
                            })
                            .catch((err) => { console.error("ERROR: uploading image urls FAILED", err) })
                    })
                    .catch((err) => { console.error("ERROR: Promises.all FAILED", err) })
            },
            updateAvatarUrlLocally(avatarUrl){
                let newUser = _.cloneDeep(this.user)
                newUser.avatarUrl = avatarUrl
                this.user = newUser
                this.$store.dispatch("updateAvatarUrl", avatarUrl)
            },
            closeImageCropper(){
                this.showImageCropper = false
            },
            editProfileImage(){
                this.showImageCropper = true
                axios.clean.get(this.user.profile.profileImageOriginal, {responseType : 'blob'})
                    .then((resp) => { this.imageCropperSource = resp.data })
                    .catch((err) => { console.error("ERROR: fetch image blob FAILED", err) })
                console.log(this.user)
            },
            updateHeadline(newHeadline){
                this.user.headeline = newHeadline
                axios.jigo.put('/v2/user/profile/headline', newHeadline)
                    .then(() => { })
                    .catch((err) => { console.error("Error: updating headline FAILED", err) })
            },
            updateInterests(newInterests){
                this.user.interests = newInterests
                const payload = {
                    interests: newInterests,
                }
                axios.jigo.put('/v2/user/profile/interests', payload)
                    .then(() => {})
                    .catch((err) => { console.error("Error: updating interests FAILED", err) })
            },
            updateAbout(newAbout){
                axios.jigo.put('/v2/user/profile/about', newAbout)
            },
            editPressed(){
                this.$refs.profileIntro.finishEditing()
                this.$refs.profileAbout.finishEditing()
            },
            fetchUserCommunities(){
                axios.jigo.get(`/v2/users/${this.username}/communities`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => { this.communities = resp.data })
                    .catch((err) => { console.error("ERROR: Fetching communities FAILED", err) })
            },
            fetchUserStats(){
                axios.jigo.get(`/v2/users/${this.username}/profile/stats`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => { this.stats = resp.data })
                    .catch((err) => { console.error("ERROR: Fetching stats FAILED", err) })
            },
            fetchUserCollaborations(){
                axios.jigo.get(`/v2/users/${this.username}/collaborations`, {validateStatus: (status) => {
                        return status < 500
                    }})
                    .then((resp) => { this.collaborations = resp.data })
                    .catch((err) => { console.error("ERROR: Fetching collaborations FAILED", err) })
            },
            fetchUserProfile(){
                let loader = this.$loading.show({ onCancel: this.loaderCanceled, });
                axios.jigo.get(`/v2/users/${this.username}/profile`, {validateStatus: (status) => {
                    return status < 500
                    }})
                    .then((resp) => {
                        loader.hide();
                        console.log("user is:",resp.data)
                        this.user = resp.data;
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("ERROR: Fetching profile FAILED", err)
                    })
            },
        }
    }
</script>

<style scoped>

</style>
