<template>
    <div class="max-w-6xl mx-auto">
        <dl class="flex justify-evenly rounded-lg bg-white shadow-lg">
            <div class="flex flex-col border-b border-white p-6 text-center sm:border-0 sm:border-r">
                <dt :class="{'profileDT': !popCard, 'popCardDT': popCard}" id="item-100">
                    Done Content Items
                </dt>
                <dd :class="{'profileDD': !popCard, 'popCardDD': popCard}" aria-describedby="item-1">
                    {{ stats.doneCount }}
                </dd>
            </div>
            <div class="flex flex-col border-t border-b border-white p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt :class="{'profileDT': !popCard, 'popCardDT': popCard}">
                    Active Subscriptions
                </dt>
                <dd :class="{'profileDD': !popCard, 'popCardDD': popCard}">
                    {{ stats.subsCount }}
                </dd>
            </div>
            <div v-if="stats.communitiesCount" class="flex flex-col border-t border-b border-white p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt :class="{'profileDT': !popCard, 'popCardDT': popCard}">
                    Communities Built
                </dt>
                <dd :class="{'profileDD': !popCard, 'popCardDD': popCard}">
                    {{ stats.communitiesCount }}
                </dd>
            </div>
            <div v-if="stats.communitiesCount" class="flex flex-col border-t border-b border-white p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt :class="{'profileDT': !popCard, 'popCardDT': popCard}">
                    Community Subscribers
                </dt>
                <dd :class="{'profileDD': !popCard, 'popCardDD': popCard}">
                    {{ stats.communitiesSubsCount }}
                </dd>
            </div>
            <div v-if="stats.communitiesCount" class="flex flex-col border-t border-b border-white p-6 text-center sm:border-0 sm:border-l sm:border-r">
                <dt :class="{'profileDT': !popCard, 'popCardDT': popCard}">
                    Community Stars
                </dt>
                <dd :class="{'profileDD': !popCard, 'popCardDD': popCard}">
                    {{ stats.communitiesStarsCount }}
                </dd>
            </div>
            <div class="flex flex-col border-t border-white p-6 text-center sm:border-0 sm:border-l">
                <dt :class="{'profileDT': !popCard, 'popCardDT': popCard}">
                    CoLearners
                </dt>
                <dd :class="{'profileDD': !popCard, 'popCardDD': popCard}">
                    {{ stats.coLearnersCount }}
                </dd>
            </div>
        </dl>
    </div>
</template>

<script>
    export default {
        name: "ProfileStats",
        props: {
            stats: {},
            popCard: {
                default: false
            },
        }
    }
</script>

<style scoped>
    .popCardDT {
        @apply mt-2 text-sm leading-6 font-medium text-gray-500
    }
    .popCardDD {
        @apply leading-none font-extrabold text-indigo-600
    }
    .profileDT {
        @apply order-2 mt-2 text-lg leading-6 font-medium text-gray-500
    }
    .profileDD {
        @apply order-1 text-5xl leading-none font-extrabold text-indigo-600
    }
</style>
