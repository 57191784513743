<template>
    <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl-white rounded-lg my-10">

        <div class="absolute right-1 top-1">
            <div v-if="canEdit">
                <button v-if="isEditing" @click="finishEditing" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                </button>

                <button v-else @click="startEditing" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                    <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/></svg>
                </button>
            </div>
        </div>

        <div class="px-6">

            <div class=" py-8 text-left">
                <div class="flex flex-wrap justify-center">
                    <h2 class="text-3xl mb-5 leading-9 tracking-tight font-extrabold text-jiruto-zotMain sm:text-4xl sm:leading-10">
                        About Me
                    </h2>
                    <div class="w-full px-4">

                        <div v-if="isEditing">
                            <div class="">
                                <div class="rounded-md shadow-sm">
                                    <textarea @input="updateAbout" v-model="about" id="about" rows="6" class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Write your own story, or others will write it for you..."/>
                                </div>
                                <p class="mt-2 text-sm text-gray-500">
                                    Tell other learners about yourself. Your motivation. You Achievements.
                                </p>
                            </div>
                        </div>
                        <div v-else>
                            <p style="white-space: pre-line" class="mb-4 text-md leading-relaxed text-gray-800">
                                {{ about }}
                            </p>
<!--                            <button class="font-normal text-pink-500">Show more</button>-->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default {
        name: "ProfileAbout",
        props: ['aboutProp', 'canEdit'],
        created() {
            this.about = this.aboutProp
        },
        data(){
            return {
                isEditing: false,
                about: null,
            }
        },
        watch: {
            aboutProp(){
                this.about = this.aboutProp
            }
        },
        methods: {
            startEditing(){
                this.$emit("editPressed")
                this.isEditing = true
            },
            finishEditing(){
                this.isEditing = false
            },
            updateAbout: _.debounce(function () {
                console.log("updating")
                this.$emit("updateAbout", this.about)
            }, 1000),
        }
    }
</script>

<style scoped>

</style>
