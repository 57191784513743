<template>
    <div class="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl-white rounded-lg my-10">

        <div v-if="canEdit" class="absolute right-1 top-1">

            <button v-if="isEditing" @click="finishEditing" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            </button>

            <button v-else @click="startEditing" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                <svg class="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/></svg>
            </button>
        </div>

        <div class="px-6">
            <div @mouseover="mousy = true" @mouseout="mousy = false" class="flex flex-wrap justify-center">
                <div class="px-4 lg:order-2 flex justify-center">
                    <div class="relative flex justify-center items-center content-center text-jiruto-main w-48 h-48 bg-gray-200 rounded-full border-4 border-white -mt-28 object-fit">

                        <div v-if="canEdit" class="absolute right-1 top-1">
                            <button @click="editImage" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 opacity-75 hover:opacity-100 p-2 hover:bg-gray-200  border-0">

                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                            </button>
                        </div>

                        <div class="h-full w-full overflow-hidden rounded-full">
                            <img
                                    alt="..."
                                    :src="user.avatarUrl"
                                    class="h-full align-middle border-none object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white px-6 pt-2 pb-8 xl:-mt-14">
                <div class="flex justify-between">
                    <div class="w-1/2 xl:w-5/12">
                        <h3 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                            {{ user.firstName + " " + user.lastName }}
                            <span class="text-2xl text-cool-gray-400">@{{ user.username }}</span>
                        </h3>
                        <div class="mt-2 w-full" v-if="isEditing">
<!--                            <label for="headline" class="block text-sm font-medium leading-5 text-gray-700">Headline</label>-->
                            <input v-model="user.profile.headline" @input="updateHeadline" id="headline" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Insert headline">
                        </div>
                        <div v-else>
                            <p class="mt-2 text-base leading-6 text-gray-500">
                                {{ user.profile.headline }}
                            </p>
                        </div>

                    </div>
                    <div v-if="userProp.username !== $store.getters.getUsername && relationships !== null" >
                        <div class="flex items-center">
                            <button v-if="relationships !== null" @click="followButtonPressed" type="submit" :class="{'bg-purple-400': relationships.following}"
                                    class="inline-flex ml-2 justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                {{ relationships.following ? "Unfollow" : "Follow"}}
                            </button>

                            <button @click="relButtonPressed" type="submit" class="inline-flex ml-2 justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                {{ coLearningButtonText }}
                            </button>

<!--                            <button v-if="relationships.coLearner === 'stranger'" @click="relButtonPressed" type="submit" class="inline-flex ml-2 justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">-->
<!--                                {{ coLearningButtonText }}-->
<!--                            </button>-->

<!--                            <button v-if="relationships.coLearner === 'youToMe'" @click="relButtonPressed" type="submit" class="inline-flex ml-2 justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">-->
<!--                                HellYeah-->
<!--                            </button>-->

                            <div class="relative inline-block text-left ml-1">
                                <div class="">
                                    <button @click="openRelMenu = !openRelMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                                        </svg>
                                    </button>
                                </div>

                                <transition enter-class="transform opacity-0 scale-y-95"
                                            enter-active-class="transition ease-out duration-100"
                                            enter-to-class="transform opacity-100 scale-y-100"
                                            leave-class="transform opacity-100 scale-y-100"
                                            leave-active-class="transition ease-in duration-75"
                                            leave-to-class="transform opacity-0 scale-y-95">

                                    <div v-if="openRelMenu" v-on-clickaway="closeRelMenu" class="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg">
                                        <div class="rounded-md bg-gray-100 shadow-xs">
                                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">



<!--                                                <button @click="revokeRequest"  class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">Revoke Request</button>-->
<!--                                                <button  @click="declineRequest" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">Decline Request</button>-->
<!--                                                <button @click="removeCoLearner" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">Remove CoLearner</button>-->

                                                <button v-if="relationships.coLearner ==='meToYou'" @click="revokeRequest" class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                    <!-- Heroicon name: pencil-alt -->
                                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"></path>
                                                    </svg>
                                                    Revoke Request
                                                </button>

                                                <button v-if="relationships.coLearner ==='youToMe'" @click="declineRequest" class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                    <!-- Heroicon name: pencil-alt -->
                                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                    </svg>
                                                    Decline Invitation
                                                </button>

                                                <button v-if="relationships.coLearner ==='coLearners'" @click="removeCoLearnerButtonPressed" class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                    <!-- Heroicon name: pencil-alt -->
                                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"></path>
                                                    </svg>
                                                    Remove CoLearner
                                                </button>

                                                <button @click="blockButtonPressed"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">
                                                    <!-- Heroicon name: pencil-alt -->
                                                    <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>
                                                    </svg>
                                                    Block User
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </transition>
                            </div>
                        </div>



                    </div>
                </div>


                <div class="mt-8">
                    <div class="flex items-center">
                        <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                            Interested In
                        </h4>
                        <div class="flex-1 border-t-2 border-gray-200"></div>
                    </div>
                    <div class="mt-2 w-1/2" v-if="isEditing">
<!--                        <label for="interests" class="block text-sm font-medium leading-5 text-gray-700">Headline</label>-->
                        <input @keyup.enter="insertInterest" v-model="newInterest" id="interests" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="Insert interest and press ENTER">
                    </div>
                    <div class="mt-2 flex flex-wrap">
                        <div v-for="(interest, index) in user.profile.interests" :key="index" class="mr-2 mt-2">
                            <div class="flex shadow-sm rounded-md">
                                <div class="flex-1 flex items-center justify-between border-l rounded-l-md border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                                    <div class="flex-1 flex px-3 py-2 text-sm leading-5 truncate">
                                        <p class="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">{{ interest }}</p>
                                        <button @click="deleteInterest(index)" class="flex items-center ml-2 hover:text-gray-400" v-if="isEditing">
                                            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <alert-modal :open="showBlockModal" v-on:close="closeBlockModal" v-on:reset="blockUser">
                <template v-slot:header>
                    You're about to block a user!
                </template>
                <template v-slot:text>
                    You've requested to block a user. Please confirm if you meant to take this action.
                </template>
                <template v-slot:button>
                    Block user
                </template>
            </alert-modal>

            <alert-modal :open="showRemoveModal" v-on:close="closeRemoveModal" v-on:reset="removeCoLearner">
                <template v-slot:header>
                    You're about to remove a CoLearner!
                </template>
                <template v-slot:text>
                    You've requested to delete an article. Please confirm if you meant to take this action.
                </template>
                <template v-slot:button>
                    Remove CoLearner
                </template>
            </alert-modal>

        </div>
    </div>
</template>

<script>
    import {directive as onClickaway} from 'vue-clickaway'
    import {jigo} from '../../axios-auth'
    import * as fb from '../../firebaseConfig'
    import _ from 'lodash'
    import AlertModal from "@/components/AlertModal";
    export default {
        name: "ProfileIntro",
        components: {AlertModal},
        props: ['userProp', 'canEdit'],
        directives: {
            onClickaway
        },
        data(){
            return{
                showRemoveModal: false,
                showBlockModal: false,
                mousy: false,
                isEditing: false,
                openRelMenu: false,
                user: null,
                newInterest: "",
                relationships: null,
                isFollowing: null,
                coLearningStatus: null,
                coLearningStatuses: {
                    iInvitedYou: "Revoke Request",
                    youInvitedMe: "ApproveRequest",
                    coLearners: "Remove CoLearner",
                    strangers: "Request CoLearner",
                }
            }
        },
        created(){
            this.user = _.cloneDeep(this.userProp)
            if(this.user.profile.interests == null){
                this.user.profile.interests = []
            }
            this.fetchRelationships()
        },
        watch: {
            userProp(newVal){
                this.user = _.cloneDeep(newVal)
                if(this.user.profile.interests == null){
                    this.user.profile.interests = []
                }
            }
        },
        computed: {
            coLearningButtonText(){
                switch(this.relationships.coLearner) {
                    case "strangers":
                        return "Invite CoLearner"
                    case "youToMe":
                        return "Approve CoLearner"
                    case "meToYou":
                        return "Request Sent"
                    case "coLearners":
                        return "CoLearners"
                    default:
                        return "Invite CoLearner"
                }
            },
            isFollowingRelationship(){
                const index = _.findIndex(this.relationships, (r) => {
                    return r.start === this.$store.getters.getUsername && r.type === "FOLLOWS"
                });
                return index !== -1
            },
        },
        methods: {
            removeCoLearnerButtonPressed(){
                this.closeRelMenu()
                this.showRemoveModal = true
            },
            blockButtonPressed(){
                this.closeRelMenu()
                this.showBlockModal = true
            },
            blockUser(){
                console.log("Blocking user!")
            },
            closeBlockModal(){
                this.showBlockModal = false
            },
            closeRemoveModal(){
                this.showRemoveModal = false
            },
            relButtonPressed(){
                switch(this.relationships.coLearner) {
                    case "strangers":
                        this.inviteCoLearner()
                        break;
                    case "youToMe":
                        this.approveCoLearner()
                        break;
                    case "meToYou":
                        break;
                    case "coLearners":
                        break;
                    default:
                        break;
                }
            },
            closeRelMenu(){
                this.openRelMenu = false
            },
            followButtonPressed(){
                this.relationships.following ? this.unFollowUser() : this.followUser()
            },
            followUser(){
                this.relationships.following = true
                jigo.post(`/v2/users/${this.userProp.username}/follow`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.relationships.following = false
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.relationships.following = false
                        console.error("ERROR: followUser FAILED", err)
                    })
            },
            unFollowUser(){
                this.relationships.following = false
                jigo.delete(`/v2/users/${this.userProp.username}/follow`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.relationships.following = true
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.relationships.following = true
                        console.error("ERROR: followUser FAILED", err)
                    })
            },
            fetchRelationships(){
                jigo.get(`/v2/users/${this.userProp.username}/getRelationships`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                        this.relationships = resp.data
                    })
                    .catch((err) => {
                        console.error("ERROR: fetchRelationships FAILED", err)
                    })
            },
            inviteCoLearner(){
                const currentStatus = this.relationships.coLearner
                this.relationships.coLearner = "meToYou"
                jigo.post(`/v2/users/${this.userProp.username}/coLearning/coLearnershipRequest`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.relationships.coLearner = currentStatus
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.relationships.coLearner = currentStatus
                        console.error("ERROR: inviteCoLearner FAILED", err)
                    })
            },
            approveCoLearner(){
                const currentStatus = this.relationships.coLearner
                this.relationships.coLearner = "coLearners"
                this.relationships.following = true
                jigo.post(`/v2/users/${this.userProp.username}/coLearning/approveCoLearner`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.relationships.coLearner = currentStatus
                            this.relationships.following = false
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.relationships.coLearner = currentStatus
                        this.relationships.following = false
                        console.error("ERROR: approveCoLearner FAILED", err)
                    })
            },
            revokeRequest(){
                this.closeRelMenu()
                const currentStatus = this.relationships.coLearner
                this.relationships.coLearner = "strangers"
                jigo.delete(`/v2/users/${this.userProp.username}/coLearning/revokeInvitation`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.relationships.coLearner = currentStatus
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.relationships.coLearner = currentStatus
                        console.error("ERROR: revokeRequest FAILED", err)
                    })
            },
            declineRequest(){
                this.closeRelMenu()
                const currentStatus = this.relationships.coLearner
                this.relationships.coLearner = "strangers"
                jigo.delete(`/v2/users/${this.userProp.username}/coLearning/declineInvitation`)
                .then((resp) => {
                    if(resp.status !== 200) {
                        this.relationships.coLearner = currentStatus
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    this.relationships.coLearner = currentStatus
                    console.error("ERROR: declineRequest FAILED", err)
                })
            },
            removeCoLearner(){
                this.closeRelMenu()
                const currentStatus = this.relationships.coLearner
                this.relationships.coLearner = "strangers"
                jigo.delete(`/v2/users/${this.userProp.username}/coLearning/removeCoLearner`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.relationships.coLearner = currentStatus
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.relationships.coLearner = currentStatus
                        console.error("ERROR: revokeCoLearnership FAILED", err)
                    })
            },
            editImage(){
                this.$emit("editImage", "profile")
            },
            profileImageSelected(e){
                const file = e.target.files[0]
                const storageRef = fb.storage.ref();
                storageRef.child("userProfile").child(`${fb.auth.currentUser.uid}/avatar`).put(file)
                .then(() => {
                })
                .catch((err) => {
                    console.error("ERROR: Uploading profile image FAILED", err)
                })
            },
            startEditing(){
                this.$emit("editPressed")
                this.isEditing = true
            },
            finishEditing(){
                this.isEditing = false
            },
            insertInterest(){
                console.log(this.newInterest)
                if (this.newInterest.trim() !== "") {
                    this.user.profile.interests.push(this.newInterest.trim())
                    this.newInterest = ""
                    this.updateInterests()
                }
            },
            deleteInterest(index){
                this.user.profile.interests.splice(index, 1)
                this.updateInterests()
            },
            updateInterests() {
                this.$emit("updateInterests", this.user.profile.interests)
            },
            updateHeadline: _.debounce(function () {
                this.$emit("updateHeadline", this.user.profile.headline)
            }, 1000),
        }
    }
</script>

<style scoped>

</style>
